@layer base {
    @font-face {
        font-family:"Myriad Pro";
        src:url('../fonts/Myriad-Pro.eot');
        src:url('../fonts/Myriad-Pro.eot?#iefix') format("embedded-opentype"),
        url('../fonts/Myriad-Pro.woff') format("woff"),
        url('../fonts/Myriad-Pro.ttf') format("truetype");
        font-weight:400;
        font-style:normal;
    }

    @font-face {
        font-family:"Myriad Pro";
        src:url('../fonts/Myriad-Pro-Bold.eot');
        src:url('../fonts/Myriad-Pro-Bold.eot?#iefix') format("embedded-opentype"),
        url('../fonts/Myriad-Pro-Bold.woff') format("woff"),
        url('../fonts/Myriad-Pro-Bold.ttf') format("truetype");
        font-weight:700;
        font-style:normal;
    }

    @font-face {
        font-family:"Phonk";
        src:url('../fonts/phonkRegular.eot');
        src:url('../fonts/phonkRegular.eot?#iefix') format("embedded-opentype"),
        url('../fonts/phonkRegular.woff') format("woff"),
        url('../fonts/phonkRegular.ttf') format("truetype");
        font-weight:400;
        font-style:normal;
    }


}